import { StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as styles from './about.module.scss';
import { Link } from 'gatsby';
import Teams from '../components/Teams';
import Fade from 'react-reveal/Fade';
import { HiArrowNarrowRight } from '@react-icons/all-files/hi/HiArrowNarrowRight';

const About = ({ data }) => {
    const [hasRan, setHasRan] = useState(false);
    const [screen, setScreen] = useState(null);
    const teamsData = data.allTeamsJson.nodes;

    const updateScreenSize = () => {
        setScreen(getScreenSize());
    };

    const getScreenSize = () => {
        const width = window.innerWidth;

        if (width >= 1300) {
            return 'xl';
        } else if (width >= 992 && width < 1300) {
            return 'large';
        } else if (width >= 600 && width < 992) {
            return 'small';
        } else {
            return 'xs';
        }
    };

    const handleResize = () => {
        setScreen(getScreenSize());
    };

    useEffect(() => {
        if (!hasRan) {
            setHasRan(true);
            updateScreenSize();
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [getScreenSize]);

    return (
        <Layout darkBg={true}>
            <div className={styles.container}>
                <div className={styles.headerContainer}>
                    <StaticImage
                        src="../images/about-header.jpg"
                        alt="Woman Learning Tech"
                        className={styles.bgImage}
                        placeholder="blurred"
                    />
                    <div className={styles.opacity}>
                        <Fade right distance="50px" duration={900}>
                            <div className={styles.items}>
                                <div className={styles.about}>
                                    <h6>ABOUT US</h6>
                                    <div className={styles.line}></div>
                                </div>

                                <h1>
                                    We are constantly empowering communities{' '}
                                    {screen === 'xs' ||
                                    screen === 'small' ? null : (
                                        <br />
                                    )}
                                    by democratizing digital education.
                                </h1>
                            </div>
                        </Fade>
                    </div>
                </div>

                <div className={styles.container2}>
                    <div className={styles.story}>
                        <Fade bottom distance="50px">
                            <h6>OUR STORY</h6>
                        </Fade>

                        <Fade bottom distance="50px" delay={50}>
                            <h5>How we started</h5>
                        </Fade>

                        <Fade bottom distance="50px" delay={100}>
                            <p>
                                Founded in 2018, Digital Society Fund (DSF) is a
                                social development initiative that aims to bring
                                quality tech education to the underprivileged
                                with the mission to bridge the digital divide in
                                our society.
                                <br />
                                <br />
                                DSF actively works with governments,
                                corporations, universities and non-profits to
                                design and implement innovative capacity
                                building programmes for the future workforce and
                                advocate for responsible internet use. We
                                currently have 20 mentors working with us and
                                have impacted more than 1150 beneficiaries.
                            </p>
                        </Fade>
                    </div>

                    <Fade bottom distance="50px">
                        <div className={styles.missions}>
                            <div className={styles.mission}>
                                <h6>Our Mission</h6>
                                <p>
                                    Bridging the digital divide in Malaysia by
                                    providing access to tech education for the
                                    underprivileged community.{' '}
                                </p>
                            </div>

                            <div className={styles.mission}>
                                <h6>Our Vision</h6>
                                <p>
                                    Using tech education to uplift the
                                    underprivileged community from income
                                    inequality, poverty and developmental
                                    stagnation.{' '}
                                </p>
                            </div>
                        </div>
                    </Fade>
                </div>

                <div className={styles.initiativesContainer}>
                    <div className={styles.bg}>
                        <div className={styles.content}>
                            <Fade bottom distance="50px">
                                <h6>OUR INITIATIVES</h6>
                            </Fade>

                            <Fade bottom distance="50px" delay={50}>
                                <h5>Striving to bridge the digital divide</h5>
                            </Fade>

                            <Fade bottom distance="50px" delay={50}>
                                <div className={styles.initiativesList}>
                                    <div className={styles.initiativeItem}>
                                        <div className={styles.innerContainer}>
                                            <div
                                                className={
                                                    styles.imageContainer
                                                }
                                            >
                                                <StaticImage
                                                    src="../images/about-initiative-2.jpg"
                                                    alt="Initiative"
                                                    className={styles.image}
                                                    placeholder="blurred"
                                                />
                                            </div>

                                            <div className={styles.texts}>
                                                <h3>
                                                    Providing formal and
                                                    vocational education
                                                </h3>
                                                <p>
                                                    DSF creates educational &
                                                    professional pathway for the
                                                    underprivileged with crucial
                                                    digital skills and tech
                                                    education to succeed in the
                                                    modern economy.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.initiativeItem}>
                                        <div className={styles.innerContainer}>
                                            <div
                                                className={
                                                    styles.imageContainer
                                                }
                                            >
                                                <StaticImage
                                                    src="../images/about-initiative-20.jpg"
                                                    alt="Initiative"
                                                    className={styles.image}
                                                    placeholder="blurred"
                                                />
                                            </div>

                                            <div className={styles.texts}>
                                                <h3>
                                                    Improving access to digital
                                                    devices & software
                                                </h3>
                                                <p>
                                                    As the transition to online
                                                    learning has resulted in
                                                    many underprivileged
                                                    students falling behind, DSF
                                                    provides high-quality
                                                    digital devices and tools
                                                    for students and communities
                                                    in need.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.initiativeItem}>
                                        <div className={styles.innerContainer}>
                                            <div
                                                className={
                                                    styles.imageContainer
                                                }
                                            >
                                                <StaticImage
                                                    src="../images/about-initiative-31.jpg"
                                                    alt="Initiative"
                                                    className={styles.image}
                                                    placeholder="blurred"
                                                />
                                            </div>

                                            <div className={styles.texts}>
                                                <h3>
                                                    Education digital
                                                    citizenship and literacy
                                                </h3>
                                                <p>
                                                    DSF aims to promote ethical
                                                    and responsible use of
                                                    technology by equipping
                                                    people with the knowledge
                                                    and skills to communicate
                                                    with others and to make
                                                    sense of digital content.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className={styles.container5}>
                    <div className={styles.bg}>
                        <div className={styles.innerContainer}>
                            <h5>Our partners and collaborators</h5>

                            <div className={styles.companiesList}>
                                <div className={styles.companyItem}>
                                    <StaticImage
                                        src="../images/about-partner-1.jpg"
                                        alt="Company"
                                        className={styles.image}
                                        placeholder="blurred"
                                        objectFit="contain"
                                    />
                                </div>

                                <div className={styles.companyItem}>
                                    <StaticImage
                                        src="../images/about-partner-2.jpg"
                                        alt="Company"
                                        className={styles.image}
                                        placeholder="blurred"
                                        objectFit="contain"
                                    />
                                </div>

                                <div className={styles.companyItem}>
                                    <StaticImage
                                        src="../images/about-partner-3.jpg"
                                        alt="Company"
                                        className={styles.image}
                                        placeholder="blurred"
                                        objectFit="contain"
                                    />
                                </div>

                                <div className={styles.companyItem}>
                                    <StaticImage
                                        src="../images/about-partner-4.jpg"
                                        alt="Company"
                                        className={styles.image}
                                        placeholder="blurred"
                                        objectFit="contain"
                                    />
                                </div>

                                <div className={styles.companyItem}>
                                    <StaticImage
                                        src="../images/about-partner-5.jpg"
                                        alt="Company"
                                        className={styles.image}
                                        placeholder="blurred"
                                        objectFit="contain"
                                    />
                                </div>
                            </div>

                            <Link className={styles.blueBtn} to="/team">
                                See More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default About;

export const TeamsQuery = graphql`
    query {
        allTeamsJson {
            nodes {
                id
                name
                role
                social1
                social1Url
                social2
                social2Url
                social3
                social3Url
                imgUrl {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
    }
`;

// <div className={styles.container3}>
// <Fade bottom distance="50px">
//     <h6>OUR TEAM</h6>
// </Fade>

// <Fade bottom distance="50px" delay={50}>
//     <h5>Meet the people behind Digital Society Fund</h5>
// </Fade>

// <Fade bottom distance="50px" delay={100}>
//     <Teams teamMembers={teamsData} />
// </Fade>

// <Fade bottom distance="50px">
//     <Link className={styles.blueBtn} to="/team">
//         See More
//     </Link>
// </Fade>
// </div>
