// extracted by mini-css-extract-plugin
export var container = "about-module--container--31sWA";
export var blueBtn = "about-module--blueBtn--2KSGI";
export var headerContainer = "about-module--headerContainer--12tKG";
export var bgImage = "about-module--bgImage--d4YCt";
export var opacity = "about-module--opacity--31GUI";
export var items = "about-module--items--3hUSa";
export var about = "about-module--about--2LggR";
export var line = "about-module--line--e-IMR";
export var container2 = "about-module--container2--pWPWS";
export var story = "about-module--story--1ebMt";
export var missions = "about-module--missions--huMPh";
export var mission = "about-module--mission--3u9Ss";
export var container3 = "about-module--container3--pNwNH";
export var initiativesContainer = "about-module--initiativesContainer--5S07y";
export var bg = "about-module--bg--3HimR";
export var content = "about-module--content--1bWMI";
export var initiativesList = "about-module--initiativesList--13I9O";
export var initiativeItem = "about-module--initiativeItem--1wYti";
export var innerContainer = "about-module--innerContainer--14fbN";
export var imageContainer = "about-module--imageContainer--3gZQA";
export var image = "about-module--image--inOzk";
export var texts = "about-module--texts--3wKDE";
export var container5 = "about-module--container5--6l57b";
export var companiesList = "about-module--companiesList--280mv";
export var teams = "about-module--teams--3xt-R";
export var team = "about-module--team--1qrvu";
export var names = "about-module--names--3blnA";
export var tooltip = "about-module--tooltip--1a9jJ";
export var social1 = "about-module--social1--1CSq5";
export var social2 = "about-module--social2--1Zu4Z";
export var social3 = "about-module--social3--1iweS";
export var fadeIn = "about-module--fadeIn--3___l";
export var bounce = "about-module--bounce--S0mNC";
export var companyItem = "about-module--companyItem--LoeFu";
export var fadeInSize = "about-module--fadeInSize--2V8dl";